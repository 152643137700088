<template>
  <div class="admins mt-4">
    <div class="header">
      <div class="buttons">
        <button
          class="btn btn-danger shadow"
          :disabled="delete_loading"
          @click="DeleteAll">
          <i class="bi bi-trash"></i>
        </button>
        <button
          class="btn btn-info shadow"
          :disabled="sync_loading"
          @click="SyncData">
          <i class="bi bi-cloud-arrow-down" v-if="!sync_loading"></i>
          <i class="bi bi-cloud-download" v-else></i>
        </button>
      </div>
    </div>
    <table class="table table-striped mt-3" v-if="_mails.length > 0">
      <thead>
        <tr>
          <th class="text-center" width="50">Sıra</th>
          <th width="150">E-Posta</th>
          <th class="text-center" width="150">İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="[index, mail] of _mails.entries()" :key="mail">
          <th class="text-center">#{{ index + 1 }}</th>
          <td>
            {{ mail.mail }}
          </td>
          <td class="text-center">
            <button
              @click="DeleteMail(mail._id)"
              class="btn btn-sm btn-danger shadow">
              <i class="bi bi-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="alert alert-warning mt-3 mb-0">
      <i class="bi bi-exclamation-triangle me-3"></i>
      Henüz mail bulunamadı. Aşağı alandan sadece excel yükleyerek mail
      ekliyebilirsiniz.
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "MailsList",
  computed: {
    ...mapGetters({
      _mails: "Mails/_mails",
    }),
  },
  data() {
    return {
      sync_loading: false,
      delete_loading: false,
    };
  },
  methods: {
    SyncData() {
      this.sync_loading = true;
      this.$store
        .dispatch("Mails/GET_MAILS")
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err?.message,
          });
        })
        .finally(() => setTimeout(() => (this.sync_loading = false), 1000));
    },
    DeleteAll() {
      this.delete_loading = true;
      this.$store
        .dispatch("Mails/DELETE_ALL_MAILS")
        .then(() => {
          this.sync_loading = true;
          this.$store
            .dispatch("Mails/GET_MAILS")
            .catch(() => {})
            .finally(() => setTimeout(() => (this.sync_loading = false), 1000));
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err?.message,
          });
        })
        .finally(() => setTimeout(() => (this.delete_loading = false), 1000));
    },
    DeleteMail(id) {
      this.sync_loading = true;
      this.$store
        .dispatch("Mails/DELETE_MAIL", id)
        .then(() => {
          this.$store.dispatch("Mails/GET_MAILS").catch((err) => {
            this.$swal({
              icon: "warning",
              text: err?.message,
            });
          });
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err?.message,
          });
        })
        .finally(() => setTimeout(() => (this.sync_loading = false), 1000));
    },
  },
};
</script>
<style lang="scss" scoped>
.admins {
  width: 100%;
  box-shadow: 0px 0px 8px 0px rgb(211, 211, 211);
  border-radius: 0.75rem;
  padding: 15px;
  background-color: white;

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .buttons {
      button:not(:last-child) {
        margin-right: 0.75rem;
      }
    }
  }

  table {
    th {
      vertical-align: middle;
      color: #272727;
      font-weight: 600;
    }

    td {
      vertical-align: middle;
    }
  }

  .d-flex {
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 15px;
    }
    h6 {
      display: flex;
      flex-direction: column;

      small {
        font-size: 11px;
        font-weight: 400;
      }
    }
  }
}
</style>
