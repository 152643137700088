<template>
  <main id="MailsView">
    <mail-software-title title="Mail Listesi" />

    <MailsList />
    <SaveMails />
  </main>
</template>

<script>
import MailsList from "@/components/Mails/MailsList";
import SaveMails from "@/components/Mails/SaveMails";
import { mapGetters } from "vuex";

export default {
  name: "MailsView",
  components: {
    MailsList,
    SaveMails,
  },
  computed: {
    ...mapGetters({
      _admin: "Auth/_admin",
    }),
  },
  mounted() {
    this.$store.dispatch("Mails/GET_MAILS", {}).catch((err) => {
      this.$swal({
        icon: "warning",
        text: err?.message,
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.comingsoon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;

  img {
    width: 500px;
  }
}
</style>
