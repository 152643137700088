<template>
  <div class="tasks" v-if="_task.length + _local_tasks.length > 0">
    <div class="header">
      <span>Devam eden işlemler</span>
      <div>
        <i class="bi bi-dash-lg" @click="clearTasks()"></i>
        <transition name="blur" mode="out-in">
          <i
            class="bi bi-chevron-down"
            v-if="_tasks_open"
            @click="closeTasks()"></i>
          <i class="bi bi-chevron-up" v-else @click="openTasks()"></i>
        </transition>
      </div>
    </div>

    <transition name="blur" mode="out-in">
      <div class="body" v-if="_tasks_open">
        <div
          class="item"
          :class="{
            success: local_task.status == 'success',
            error: local_task.status == 'error',
            warning: local_task.status == 'half',
          }"
          v-for="local_task of _local_tasks"
          :key="local_task">
          <i
            class="bi bi-file-earmark-arrow-up"
            v-if="local_task.type == 'file_upload'"></i>
          <i class="bi bi-envelope" v-if="local_task.type == 'mail'"></i>
          <i class="bi bi-chat-left" v-if="local_task.type == 'sms'"></i>
          <div class="d-flex flex-column">
            <span>{{ local_task.text }}</span>
            <span>{{ local_task.completed }} / {{ local_task.data }}</span>
          </div>
        </div>
        <div
          class="item"
          :class="{
            success: task.status == 'success',
            error: task.status == 'error',
          }"
          v-for="task of _task"
          :key="task">
          <i
            class="bi bi-file-earmark-arrow-up"
            v-if="task.type == 'file_upload'"></i>
          <i class="bi bi-envelope" v-if="task.type == 'mail'"></i>
          <i class="bi bi-chat-left" v-if="task.type == 'sms'"></i>
          <div class="d-flex flex-column">
            <span>{{ task.text }}</span>
            <span>{{ task.completed }} / {{ task.data }}</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "AppTasks",
  computed: {
    ...mapGetters({
      _task: "Tasks/_tasks",
      _local_tasks: "Tasks/_local_tasks",
      _tasks_open: "Tasks/_tasks_open",
    }),
  },
  methods: {
    openTasks() {
      this.$store.commit("Tasks/setTasksOpen", true);
    },
    closeTasks() {
      this.$store.commit("Tasks/setTasksOpen", false);
    },
    clearTasks() {
      this.$store.commit("Tasks/setTasksOpen", false);
      this.$store.commit("Tasks/setTasks", []);
    },
  },
  mounted() {
    setInterval(() => {
      this.$store.dispatch("Tasks/GET_TASKS", {}).catch(() => {});
    }, 1000);
  },
};
</script>
<style lang="scss" scoped>
.tasks {
  width: 500px;
  padding: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: white;
  position: fixed;
  right: 15px;
  bottom: 0px;
  box-shadow: 2px 4px 16px -2px rgba(var(--bs-dark-rgb), 0.15);
  border: 1px solid #c9c9c9;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    i {
      padding: 5px;
      border-radius: 50%;
      transition: 0.3s all ease-in-out;
      color: black;
      border: 1px solid #fff;
      width: 36px;
      height: 36px;
      display: inline-block;
      text-align: center;
      &:hover {
        background-color: #cacaca;
      }
      &:active {
        border: 1px solid #a5a5a5;
      }
    }
  }

  .body {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .item {
      display: flex;
      gap: 10px;
      background-color: rgb(151, 183, 253);
      border-radius: 15px;
      padding: 15px;
      font-weight: 600;
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #1f3b66;
        color: white;
      }

      &.success {
        background-color: rgb(195, 253, 170);
        i {
          background-color: #235a12;
        }
      }
      &.error {
        background-color: rgb(253, 170, 170);
        i {
          background-color: #5a1212;
        }
      }
    }
  }
}
</style>
