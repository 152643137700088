<template>
  <div class="container mx-auto row justify-content-center my-5 pb-3">
    <div class="col-8">
      <form
        class="d-flex flex-column align-items-center"
        @submit.prevent="saveMails()">
        <textarea
          v-model="content"
          class="form-control w-100 mb-3"
          placeholder="Mailleri giriniz"
          cols="5"></textarea>
        <transition name="blur" mode="out-in">
          <div class="buttons d-flex mb-3" v-if="content">
            <button
              type="reset"
              @click="clearFile"
              class="btn btn-sm btn-danger btn-iconed shadow me-2">
              Vazgeç
              <i class="bi bi-x-lg ms-2"></i>
            </button>
            <button
              type="submit"
              class="btn btn-sm btn-secondary btn-iconed shadow ms-2">
              Yükle
              <i class="bi bi-cloud-upload ms-2"></i>
            </button>
          </div>
        </transition>
      </form>
      <div class="alert alert-info d-flex align-items-center mb-0">
        Mail adreslerini alt alta yazınız. ÖR:
        <br />
        <br />
        ornek@mail.com <br />
        ornek2@mail.com <br />
        ...
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SaveMails",
  data() {
    return {
      content: "",
      buttons: false,
    };
  },
  methods: {
    clearContent() {
      this.content = "";
    },
    saveMails() {
      this.$store
        .dispatch("Mails/SAVE_MAILS", {
          content: this.content,
        })
        .then((res) => {
          this.clearContent();
          this.$swal({
            icon: "success",
            text: res?.message,
          });
          this.$store.dispatch("Mails/GET_MAILS").catch((err) => {
            this.$swal({
              icon: "warning",
              text: err.message,
            });
          });
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            text: err?.message,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.form-file-label {
  padding: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  font-size: 1.35rem;
  border-radius: 0.5rem;
  font-weight: 600;
  width: 100%;
  margin-bottom: 1.5rem;
  color: rgba(var(--bs-dark-rgb), 0.8);
  border: 2px dashed rgb(var(--bs-secondary-rgb));
  background: rgb(var(--bs-white-rgb));
  transition: all 300ms ease-in-out;
  &::before {
    content: "Mailleri yüklemek için bir txt dosyası seçiniz";
  }

  &.active {
    color: rgba(var(--bs-success-rgb), 0.8);
    border: 2px dashed rgb(var(--bs-success-rgb));
    background: rgba(var(--bs-success-rgb), 0.1);
    &::before {
      content: "Dosya seçiminiz: " attr(data-file);
    }
  }
}
</style>
